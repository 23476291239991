.navbar {
    background: #003f53;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: all 0.5s ease-in-out;
  }


/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}


.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background: #003f53;
    opacity: 0.9;
    filter: alpha(opacity=70);
    zoom: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #88c0ad;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-links:hover {
    color: #88c0ad;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #003f53;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }