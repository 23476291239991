:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 64px;
    font-size: 20px;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
}

.btn--wide:hover {
    transition: all 0.3s ease-out;
    color: #fff;
    transform: scale(1.2);
}

.blueGreen {
    background-color: #36ae94;
    color: #fff;
    border: none;
}

.lightGreen {
    background-color: #009e6f;
    color: #fff;
    border: none;
}

.green {
    background-color: #00856a;
    color: #fff;
    border: none;
}

.primary {
    background-color: #003f53;
    color: #fff;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #003f53;
}