* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.services-section {
  min-height: 100vh;
}

.background-container {
  background-color: #fafafa;
}