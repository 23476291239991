.footer-container {
    background-color: #003f53;
    padding: 0rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    width: 100%;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link-contacts {
    color: #454545;
    font-size: 32px;
  }
  
  .social-media {
    max-width: 1000px;
    min-width: 100%;
    background-color: #003f53;
    bottom: 0;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 20px auto 0 auto;
  }
  
  .social-icons-contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

.formClass {
  width: 40%;
}

.feedback-input {
  color:#88c0ad;
  font-weight:500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border:2px solid #fff;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}

.feedback-input:focus { border:2px solid #88c0ad; }

textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

.contacts-container {
  width: 100%;
  background-color: #fafafa;;
}

.flex-row-gap {
  margin: 30px auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 10px
}

.contacts-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.no-decoration {
  text-decoration: none;
  color: #454545;
}

.contacts-items h2 {
  margin-bottom: 16px;
}

.contacts-location {
  display: none;
}

.map {
  width: 400px;
  height: 400px;
}

/* .footer-link-items > h2 {
  color: #fff;
} */

[type="submit"] {
  width: 100%;
  background:none;
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:#fff;
  font-size:24px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}
[type="submit"]:hover { background:#88c0ad; color:#fff;}
  
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  
    .footer-logo {
      display: none;
    }

    .social-media {
      bottom: -60px;
    }

    .formClass {
      width: 80%;
    }

    .flex-row-gap {
      margin: 30px auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 10px
    }

    .contacts-location {
      display: block;
    }

    .map {
      width: 95%;
      height: 350px;
      margin: 10px auto;
    }

    .map-container {
      width: 100%;
      text-align: center;
    }

    .contacts-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px auto;
      text-align: center;
      width: 160px;
      box-sizing: border-box;
    }
    
  }