@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.container-services {
    width: 100%;
    height: 100%;
    padding: 0px 8%;
    box-sizing: border-box;
    font-family: 'PT Sans', sans-serif;
    background-color: #fafafa;
}


.container-services h1 {
    text-align: center;
    padding-top: 10%;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
}

.container-services h1::after {
    content: '';
    background: #88c0ad;
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

.row-services {
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(min(100%/3, max(250px, 100%/5)), 1fr));
    grid-gap: 30px;
}

.service {
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    transition: transform 0.5s background 0.5s;
}

.service i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #88c0ad;
}

.service h2 {
    font-weight: 600;
    margin-bottom: 8px;
}

.service:hover {
    background: #88c0ad;
    color: #fff;
    transform: scale(1.05);
}

.service:hover i {
    color: #fff;
}
