.home-hero-section {
    color: #fff;
    padding: 150px 0;
    min-height: 100vh;
    scroll-snap-align: start;
    background-color: #fafafa;
}

.home-hero-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.home-hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
}

.top-line {
    color: #88c0ad;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}

.dark {
    color: #1c2237;
}

.darkBg {
    background-color: #003f53;
}

.home-hero-subtitle {
    max-width: 500px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}

.home-hero-img-wrapper {
    max-width: 555px;
}

.home-hero-img {
    max-width: 95%;
    margin: 0 0 0 10px;
    padding-right: 0;
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.list {
    display: grid;
    grid-template: "a a"
                   "b b";
}

.list-item {
    display: flex;
    flex-direction: column;
    color: #000;
    width: 200px;
    height: 180px;
    font-size: 14px;
    font-weight: bold;
    background-color: #fafafa;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 0.5s;
    margin-bottom: 50px;
}

.list-image {
    margin-bottom: 20px;
}

/* .list-item:hover{
    transform: scale(1.4) rotate(5deg);
    animation-delay: 2s;
} */

.flat-list-item {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 15px;
}

@media screen and (max-width: 951px) {
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }  
    
}

@media screen and (max-width: 850px) {
    .home-hero-text-wrapper {
        padding-bottom: 65px;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
    }

    .list {
        display: grid;
        grid-template: "a"
                       "b"
                       "c"
                       "d";
        justify-items: center;
    }
}