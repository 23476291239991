@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.services-section {
  min-height: 100vh;
}

.background-container {
  background-color: #fafafa;
}
:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 64px;
    font-size: 20px;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }

.btn--large:hover, .btn--medium:hover, .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
}

.btn--wide:hover {
    transition: all 0.3s ease-out;
    color: #fff;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.blueGreen {
    background-color: #36ae94;
    color: #fff;
    border: none;
}

.lightGreen {
    background-color: #009e6f;
    color: #fff;
    border: none;
}

.green {
    background-color: #00856a;
    color: #fff;
    border: none;
}

.primary {
    background-color: #003f53;
    color: #fff;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #003f53;
}
.home-hero-section {
    color: #fff;
    padding: 150px 0;
    min-height: 100vh;
    scroll-snap-align: start;
    background-color: #fafafa;
}

.home-hero-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1 1;
    max-width: 50%;
    flex-basis: 50%;
}

.home-hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
}

.top-line {
    color: #88c0ad;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
}

.dark {
    color: #1c2237;
}

.darkBg {
    background-color: #003f53;
}

.home-hero-subtitle {
    max-width: 500px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
}

.home-hero-img-wrapper {
    max-width: 555px;
}

.home-hero-img {
    max-width: 95%;
    margin: 0 0 0 10px;
    padding-right: 0;
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.list {
    display: grid;
    grid-template: "a a"
                   "b b";
}

.list-item {
    display: flex;
    flex-direction: column;
    color: #000;
    width: 200px;
    height: 180px;
    font-size: 14px;
    font-weight: bold;
    background-color: #fafafa;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: all 0.5s;
    margin-bottom: 50px;
}

.list-image {
    margin-bottom: 20px;
}

/* .list-item:hover{
    transform: scale(1.4) rotate(5deg);
    animation-delay: 2s;
} */

.flat-list-item {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 15px;
}

@media screen and (max-width: 951px) {
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }  
    
}

@media screen and (max-width: 850px) {
    .home-hero-text-wrapper {
        padding-bottom: 65px;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
    }

    .list {
        display: grid;
        grid-template: "a"
                       "b"
                       "c"
                       "d";
        justify-items: center;
    }
}
.landingContainer {
    background: #003f53;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    top: -80px;
}

.landingBg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 112%;
    overflow: hidden;
}

.videoBg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #003f53;
}

.landingContent {
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landingContent h1 {
    color: #fff;
    font-size: 48px;
    text-align: center;

}

.landingContent p {
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    margin-bottom: 44px;
}

@media screen and (max-width: 768px) {
    .landingContent h1 {
        font-size: 40px;
    }  
    .landingContent p {
        font-size: 22px;
    }
}

@media screen and (max-width: 480px) {
    .landingContent h1 {
        font-size: 32px;
    }
    .landingContent p {
        font-size: 18px;
    }
}
.pricing-section {
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
}

.pricing-container-card {
    background: linear-gradient(45deg, #00856a 0%, #009e6f 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 580px;
    text-decoration: none;
    border-radius: 4px;
}

.pricing-container-card:nth-child(2) {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #242424;
    margin: 0 24px;
}

.pricing-container-card:hover {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
}

.pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.pricing-heading {
    color: #1c2237;
    margin-bottom: 24px;
}

.pricing-container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: #fff;
}

.pricing-container-card h3 {
    margin-bottom: 5px;
    font-size: 24px;
}

.pricing-container-card h4 {
    font-size: 40px;
}

.pricing-container-card p {
    font-size: 14px;
    margin-bottom: 24px;
}

.pricing-container-features {
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing-container-features li{
    margin-bottom: 10px;
}

.icon {
    margin: 24px 0;
}

@media screen and (max-width: 960px) {
    .pricing-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing-container-card {
        width: 90%;
    }

    .pricing-wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing-container-card:nth-child(2) {
        -webkit-transform: scale(1);
                transform: scale(1);
        background: #242424;
        margin: 24px;
    }

    .pricing-container-card:hover {
        -webkit-transform: none;
                transform: none;
    }
}

@media (max-width: 768px) {
.footer-container {
    background-color: #003f53;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  flex-wrap: wrap;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #003f53;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    width: 100%;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  
  .modal-title {
    color: #111827;
    padding: 1.5em 1em;
    pointer-events: all;
    position: relative;
    width: '90%';
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: normal;
  }
  
  .modal-content {
    border-top: 1px solid #e0e0e0;
    padding: 2em;
    pointer-events: all;
    overflow: auto;
    color: #6b7280;
    line-height: 20px;
    text-align: justify;
    margin-bottom: 10px;
  }
  
  
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  
    .footer-logo {
      display: none;
    }
  }
}
.navbar {
    background: #003f53;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: all 0.5s ease-in-out;
  }


/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}


.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background: #003f53;
    opacity: 0.9;
    filter: alpha(opacity=70);
    zoom: 1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}


/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #88c0ad;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-links:hover {
    color: #88c0ad;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.3s ease;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #003f53;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }
@media only screen and (max-device-width: 900px) {
    .home { width:100%; }
    }
.footer-container {
    background-color: #003f53;
    padding: 0rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    width: 100%;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link-contacts {
    color: #454545;
    font-size: 32px;
  }
  
  .social-media {
    max-width: 1000px;
    min-width: 100%;
    background-color: #003f53;
    bottom: 0;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 20px auto 0 auto;
  }
  
  .social-icons-contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

.formClass {
  width: 40%;
}

.feedback-input {
  color:#88c0ad;
  font-weight:500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border:2px solid #fff;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width:100%;
  box-sizing: border-box;
  outline:0;
}

.feedback-input:focus { border:2px solid #88c0ad; }

textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

.contacts-container {
  width: 100%;
  background-color: #fafafa;;
}

.flex-row-gap {
  margin: 30px auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px
}

.contacts-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.no-decoration {
  text-decoration: none;
  color: #454545;
}

.contacts-items h2 {
  margin-bottom: 16px;
}

.contacts-location {
  display: none;
}

.map {
  width: 400px;
  height: 400px;
}

/* .footer-link-items > h2 {
  color: #fff;
} */

[type="submit"] {
  width: 100%;
  background:none;
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:#fff;
  font-size:24px;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}
[type="submit"]:hover { background:#88c0ad; color:#fff;}
  
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  
    .footer-logo {
      display: none;
    }

    .social-media {
      bottom: -60px;
    }

    .formClass {
      width: 80%;
    }

    .flex-row-gap {
      margin: 30px auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
      gap: 10px
    }

    .contacts-location {
      display: block;
    }

    .map {
      width: 95%;
      height: 350px;
      margin: 10px auto;
    }

    .map-container {
      width: 100%;
      text-align: center;
    }

    .contacts-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px auto;
      text-align: center;
      width: 160px;
      box-sizing: border-box;
    }
    
  }
.container-services {
    width: 100%;
    height: 100%;
    padding: 0px 8%;
    box-sizing: border-box;
    font-family: 'PT Sans', sans-serif;
    background-color: #fafafa;
}


.container-services h1 {
    text-align: center;
    padding-top: 10%;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
}

.container-services h1::after {
    content: '';
    background: #88c0ad;
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.row-services {
    display: grid;
    grid-template-columns:repeat(auto-fit, minmax(min(100%/3, max(250px, 100%/5)), 1fr));
    grid-gap: 30px;
}

.service {
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    transition: -webkit-transform 0.5s background 0.5s;
    transition: transform 0.5s background 0.5s;
    transition: transform 0.5s background 0.5s, -webkit-transform 0.5s background 0.5s;
}

.service i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #88c0ad;
}

.service h2 {
    font-weight: 600;
    margin-bottom: 8px;
}

.service:hover {
    background: #88c0ad;
    color: #fff;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.service:hover i {
    color: #fff;
}


.pricing-section {
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
}

.pricing-container-card {
    background: linear-gradient(45deg, #00856a 0%, #009e6f 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 580px;
    text-decoration: none;
    border-radius: 4px;
}

    .pricing-container-card:nth-child(2) {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        background: #242424;
        margin: 0 24px;
    }

    .pricing-container-card:hover {
        -webkit-transform: scale(1.06);
                transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

.pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.pricing-heading {
    color: #1c2237;
    margin-bottom: 24px;
}

.pricing-container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: #fff;
}

.pricing-container-card h3 {
    margin-bottom: 5px;
    font-size: 24px;
}

.pricing-container-card h4 {
    font-size: 40px;
}

.pricing-container-card p {
    font-size: 14px;
    margin-bottom: 24px;
}

.pricing-container-features {
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .pricing-container-features li {
        margin-bottom: 10px;
    }

.icon {
    margin: 24px 0;
}

@media screen and (max-width: 960px) {
    .pricing-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing-container-card {
        width: 90%;
    }

    .pricing-wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing-container-card:nth-child(2) {
        -webkit-transform: scale(1);
                transform: scale(1);
        background: #242424;
        margin: 24px;
    }

    .pricing-container-card:hover {
        -webkit-transform: none;
                transform: none;
    }
}


