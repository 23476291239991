.pricing-section {
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
}

.pricing-container-card {
    background: linear-gradient(45deg, #00856a 0%, #009e6f 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 580px;
    text-decoration: none;
    border-radius: 4px;
}

    .pricing-container-card:nth-child(2) {
        transform: scale(1.05);
        background: #242424;
        margin: 0 24px;
    }

    .pricing-container-card:hover {
        transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

.pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.pricing-heading {
    color: #1c2237;
    margin-bottom: 24px;
}

.pricing-container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;
    align-items: center;
    color: #fff;
}

.pricing-container-card h3 {
    margin-bottom: 5px;
    font-size: 24px;
}

.pricing-container-card h4 {
    font-size: 40px;
}

.pricing-container-card p {
    font-size: 14px;
    margin-bottom: 24px;
}

.pricing-container-features {
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .pricing-container-features li {
        margin-bottom: 10px;
    }

.icon {
    margin: 24px 0;
}

@media screen and (max-width: 960px) {
    .pricing-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing-container-card {
        width: 90%;
    }

    .pricing-wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing-container-card:nth-child(2) {
        transform: scale(1);
        background: #242424;
        margin: 24px;
    }

    .pricing-container-card:hover {
        transform: none;
    }
}
